<template>
  <div class="locations p-8 mx-auto max-w-5xl">
    <div class="max-w-3xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold">Add Location</h1>
      </div>
      <form @submit.prevent="submitLocation" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div>
            <label
              for="location_address1"
              class="block text-sm font-medium text-gray-600"
            >
              Address <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                placeholder=""
                v-model="location.location_address1"
                ref="address1"
                maxlength="45"
                name="location_address1"
                class="w-full border rounded px-4 py-2"
              />
            </div>

            <div class="mt-3">
              <input
                v-model="location.location_address2"
                ref="address2"
                name="location_address2"
                maxlength="45"
                class="w-full border rounded px-4 py-2"
              />
            </div>

            <div class="mt-3 flex gap-2">
              <div class="w-full">
                <label
                  for="location_city"
                  class="block text-sm font-medium text-gray-600"
                >
                  City <span class="text-red-600">*</span>
                </label>
                <input
                  v-model="location.location_city"
                  required
                  name="location_city"
                  class="w-full border rounded px-4 py-2"
                />
              </div>
              <div class="w-full">
                <label
                  for="location_state"
                  class="block text-sm font-medium text-gray-600"
                >
                  State <span class="text-red-600">*</span>
                </label>
                <select
                  v-model="location.location_state"
                  required
                  name="location_state"
                  class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
                >
                  <option value=""> </option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div class="w-full">
                <label
                  for="location_zip"
                  class="block text-sm font-medium text-gray-600"
                >
                  Zip <span class="text-red-600">*</span>
                </label>
                <input
                  v-model="location.location_zip"
                  required
                  name="location_zip"
                  class="w-full border rounded px-4 py-2"
                />
              </div>
            </div>
          </div>

          <div>
            <label
              for="location_notes"
              class="block text-sm font-medium text-gray-600"
            >
              Location Notes (Internal Use)
            </label>
            <div class="mt-1">
              <textarea
                v-model="location.location_notes"
                name="location_notes"
                class="w-full border rounded px-4 py-2 border-gray-200"
                rows="5"
              />
            </div>
          </div>

          <div>
            <label
              for="location_status"
              class="block text-sm font-medium text-gray-600"
            >
              Status
            </label>
            <div class="mt-1">
              <select
                v-model="location.location_status"
                required
                name="location_status"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>

          <div class="pt-2 flex justify-center">
            <button
              type="submit"
              class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
            >
              <div v-if="isPending" class="spinner"></div>
              Save
            </button>
          </div>

          <div class="text-center text-red-600 font-semibold">
            {{ error }} {{ customer_err }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { fieldvalue, timestamp } from "@/firebaseConfig";
import { useRoute, useRouter } from "vue-router";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";

export default {
  props: ["user"],
  setup(props) {
    const isPending = ref(false);
    const route = useRoute();
    const router = useRouter();
    const parent = route.path.substring(0, route.path.lastIndexOf("/"));
    const address1 = ref(null);
    const address2 = ref(null);

    const location = ref({
      location_status: "active",
    });

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, addDoc } = useCollection(
      `businesses/${business_id}/locations`
    );

    const { error: customer_err, updateDoc } = useDocument(
      `businesses/${business_id}/customers`,
      route.params.customer_id
    );

    onMounted(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        address1.value,
        {
          componentRestrictions: { country: ["us", "ca"] },
          placeholder: "",
        }
      );
      autocomplete.addListener("place_changed", () => {
        let street_number = location.value.location_address1.split(" ").shift();
        let place = {};
        const placeObj = autocomplete.getPlace();
        placeObj.address_components.forEach((element) => {
          place[element.types[0]] = element.short_name;
        });
        location.value.location_city = place.locality;
        location.value.location_state = place.administrative_area_level_1;
        location.value.location_zip = place.postal_code;
        location.value.location_address1 =
          (place.street_number ?? street_number) + " " + place.route;
        address2.value.focus();
      });
    });

    const submitLocation = async () => {
      isPending.value = true;

      const address =
        `${location.value.location_address1}, ` +
        (location.value.location_address2
          ? `${location.value.location_address2}, `
          : "") +
        `${location.value.location_city} ${location.value.location_state} ${location.value.location_zip}`;

      const googleGeocode = new Promise((resolve) => {
        new window.google.maps.Geocoder().geocode(
          { address: address },
          async (result, status) => {
            if (status == "OK") {
              resolve({
                coordinates: {
                  lat: result[0].geometry.location.lat(),
                  long: result[0].geometry.location.lng(),
                },
                location_type: result[0].geometry.location_type,
              });
            } else {
              resolve({});
            }
          }
        );
      });

      const snapshot = await googleGeocode;

      const loc = await addDoc({
        createdAt: timestamp(),
        createdBy: props.user.uid,
        location_address1: location.value.location_address1,
        location_address2: location.value.location_address2 ?? "",
        location_city: location.value.location_city,
        location_state: location.value.location_state,
        location_zip: location.value.location_zip,
        location_notes: location.value.location_notes ?? "",
        location_status: location.value.location_status,
        location_coordinates: snapshot.coordinates ?? "",
        location_type: snapshot.location_type ?? "",
        ...(route.params.customer_id && {
          customers: [route.params.customer_id],
        }),
      });

      if (route.params.customer_id) {
        await updateDoc({
          locations: fieldvalue.arrayUnion(loc.id),
        });
      }

      isPending.value = false;

      if (!error.value) {
        router.push(`${parent}/${loc.id}`);
      }
    };

    return {
      router,
      location,
      error,
      customer_err,
      submitLocation,
      isPending,
      address1,
      address2,
    };
  },
};
</script>
